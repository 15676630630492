import { render, staticRenderFns } from "./Management.vue?vue&type=template&id=4a3ccf85&"
import script from "./Management.vue?vue&type=script&lang=js&"
export * from "./Management.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a3ccf85')) {
      api.createRecord('4a3ccf85', component.options)
    } else {
      api.reload('4a3ccf85', component.options)
    }
    module.hot.accept("./Management.vue?vue&type=template&id=4a3ccf85&", function () {
      api.rerender('4a3ccf85', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sepa/Management.vue"
export default component.exports