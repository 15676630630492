<template>
  <b-modal
    id="selectOption"
    size="sm"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    @hidden="onModalHidden"
  >
    <template v-if="title" #modal-header="">
      <div slot="modal-title" class="modal-title">
        <span>{{ title }}</span>
      </div>
    </template>

    <b-row v-if="description">
      <div class="col-md-12 mb-3">{{ description }}</div>
    </b-row>
    <b-row>
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <div class="col-md-12">
          <!-- <base-datepicker
            name="setup_at"
            label="Data istanziamento SDD"
            v-model="form.setup_at"
            :rules="{ required: true }"
          ></base-datepicker> -->
          <base-month-picker
            name="setup_at"
            label="Data istanziamento SDD"
            v-model="form.setup_at"
            :rules="{ required: true }"
          />
        </div>
        <div class="col-md-12">
          <base-select
            v-model="form.bank_account_id"
            name="bank"
            label="Banca"
            :rules="{ required: true }"
            :options="bank_accounts"
            @select="onSelectOption"
          />
        </div>
        <div class="col-md-12">
          <base-select
            v-model="form.insurers_id"
            name="company"
            label="Compagnia assicuratrice"
            :options="companies"
          />
          <!--
          <base-select
            v-model="form.insurers_id"
            name="company"
            label="Compagnie assicuratrici"
            :options="companies"
            :multiple="true"
            :taggable="true"
            :closeOnSelect="false"
          />
          <b-button
            :pressed.sync="myToggleCompanies"
            variant="light"
            class="mb-2"
            @click="
              myToggleCompanies ? selectAllCompanies() : deselectAllCompanies()
            "
            >{{
              myToggleCompanies ? "Deseleziona tutti" : "Seleziona tutti"
            }}</b-button
          >
          -->
        </div>
        <div class="col-md-12 actions">
          <b-button @click="hideModal()" size="sm" variant="outline-secondary"
            >ANNULLA E CHIUDI</b-button
          >
          <b-button
            @click="handleSubmit(createEvent)"
            type="button"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class=""
          >
            Salva
          </b-button>
        </div>
      </validation-observer>
    </b-row>
  </b-modal>
</template>
<script>
// import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseMonthPicker from "@/components/form/BaseMonthPicker";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters } from "vuex";
import FormMixin from "@/mixins/FormMixin";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [FormMixin],
  data() {
    return {
      myToggleCompanies: false,
      companies: [],
      bank_accounts: [],
      form: {
        setup_at: null,
        insurers_id: [],
        bank_account_id: null,
      },
    };
  },
  components: {
    BaseSelect,
    BaseMonthPicker,
    // BaseDatepicker,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  computed: {},
  beforeMount() {},
  created() {
    this.companies = this.getInsurers();
    this.bank_accounts = this.getBankAccounts();
  },
  methods: {
    onModalHidden() {
      this.form.setup_at = null;
      this.form.bank_account_id = null;
      this.form.insurers_id = [];
    },
    hideModal() {
      this.$bvModal.hide("selectOption");
    },
    createEvent() {
      const formatted_setup_at = moment(this.form.setup_at).format("YYYY-MM");
      const days_in_month = moment(this.form.setup_at, "YYYY-MM").daysInMonth();
      this.form.setup_at = `${formatted_setup_at}-${days_in_month}`;
      this.$emit("create", this.form);
      this.hideModal();
    },
    onSelectOption(selected) {
      this.option = selected;
    },
    selectAllCompanies() {
      this.form.insurers_id = this.companies.map((e) => e.value);
    },
    deselectAllCompanies() {
      this.form.insurers_id = [];
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getBankAccounts: "bank_accounts",
    }),
  },
};
</script>
<style lang="scss" scoped>
.modal-body .row > * {
  width: 100%;
}
.modal-body .actions {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
}
</style>
