var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-overlay",
            {
              attrs: { center: "", rounded: "sm" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center mt-5" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "stopwatch",
                                "font-scale": "3",
                                animation: "cylon",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { attrs: { id: "cancel-label" } }, [
                              _vm._v("Operazione in corso..."),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1492235527
              ),
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { align: "left" } },
                    [
                      _c(
                        "b-button-group",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:collapse-1",
                              arg: "collapse-1",
                            },
                          ],
                          staticClass: "my-2 filter-button-group",
                          attrs: { title: _vm.getDefaultFilterMessage() },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "when-open" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "when-closed" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v("\n          Filtra\n        "),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { align: "right" } },
                    [
                      _vm.canVerb(_vm.resource, "store")
                        ? _c(
                            "b-button",
                            {
                              staticClass: "btn-create",
                              attrs: {
                                id: `popover-create`,
                                type: "button",
                                variant: "primary",
                                size: "sm",
                                title: "Crea",
                              },
                              on: { click: _vm.onAdd },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "plus-circle",
                                  "aria-hidden": "true",
                                },
                              }),
                              _vm._v("\n          Crea"),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                { attrs: { visible: "", id: "collapse-1" } },
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.onSearch(_vm.filterName)
                        },
                      },
                    },
                    [
                      _c(
                        "b-card",
                        { staticClass: "filter" },
                        [
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "bank",
                                    label: "Banca",
                                    options: _vm.bank_accounts,
                                  },
                                  model: {
                                    value: _vm.filter.byBankAccount.id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byBankAccount,
                                        "id",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byBankAccount.id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-month-picker", {
                                  attrs: {
                                    name: "setup_at",
                                    label: "Data istanziamento SDD",
                                  },
                                  model: {
                                    value: _vm.filter.byAttribute.setup_at,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byAttribute,
                                        "setup_at",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byAttribute.setup_at",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group col-md-3 align-self-end",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle:collapse-1",
                                        arg: "collapse-1",
                                      },
                                    ],
                                    attrs: {
                                      type: "submit",
                                      variant: "lisaweb",
                                      size: "sm",
                                    },
                                  },
                                  [_vm._v("Cerca")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-reset",
                                    attrs: {
                                      type: "button",
                                      variant: "lisaweb",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onClearFilter(_vm.filterName)
                                      },
                                    },
                                  },
                                  [_vm._v("Reset")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("direct-debits-table", {
                ref: _vm.tableRef,
                attrs: {
                  fields: _vm.fields,
                  repository: _vm.repository,
                  resource: _vm.resource,
                  filterName: _vm.filterName,
                  filterOn: {
                    byRelations: ["byBankAccount", "byPaymentProcedure"],
                  },
                  onlyActions: ["edit", "destroy"],
                },
                on: {
                  edit: _vm.onEdit,
                  download: _vm.downloadXml,
                  destroy: _vm.onDestroy,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("multi-select-option-modal", {
                    attrs: { title: "Creazione nuovo flusso", description: "" },
                    on: { create: _vm.create },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }