var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "selectOption",
        size: "sm",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "hide-footer": "",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: { hidden: _vm.onModalHidden },
      scopedSlots: _vm._u(
        [
          _vm.title
            ? {
                key: "modal-header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "modal-title",
                        attrs: { slot: "modal-title" },
                        slot: "modal-title",
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.title))])]
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _vm.description
        ? _c("b-row", [
            _c("div", { staticClass: "col-md-12 mb-3" }, [
              _vm._v(_vm._s(_vm.description)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit, invalid }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("base-month-picker", {
                          attrs: {
                            name: "setup_at",
                            label: "Data istanziamento SDD",
                            rules: { required: true },
                          },
                          model: {
                            value: _vm.form.setup_at,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "setup_at", $$v)
                            },
                            expression: "form.setup_at",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("base-select", {
                          attrs: {
                            name: "bank",
                            label: "Banca",
                            rules: { required: true },
                            options: _vm.bank_accounts,
                          },
                          on: { select: _vm.onSelectOption },
                          model: {
                            value: _vm.form.bank_account_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "bank_account_id", $$v)
                            },
                            expression: "form.bank_account_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("base-select", {
                          attrs: {
                            name: "company",
                            label: "Compagnia assicuratrice",
                            options: _vm.companies,
                          },
                          model: {
                            value: _vm.form.insurers_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "insurers_id", $$v)
                            },
                            expression: "form.insurers_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-12 actions" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "outline-secondary" },
                            on: {
                              click: function ($event) {
                                return _vm.hideModal()
                              },
                            },
                          },
                          [_vm._v("ANNULLA E CHIUDI")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "button",
                              disabled: invalid,
                              variant: "outline-lisaweb",
                              size: "sm",
                            },
                            on: {
                              click: function ($event) {
                                return handleSubmit(_vm.createEvent)
                              },
                            },
                          },
                          [_vm._v("\n          Salva\n        ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }